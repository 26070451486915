import { Link } from '@remix-run/react';
import { GenerusLogo } from '@/assets/icons';

const Footer = () => {
  return (
    <footer className="w-full bg-[#252525]">
      <div className="flex flex-col items-center space-y-4 text-center p-6 md:p-10">
        <p className="text-[#EEEDA8] my-2 text-base font-general-sans-medium">
          Powered by:
        </p>
        <GenerusLogo />
        <div className="flex flex-col md:flex-row text-[#919189] font-general-sans-medium items-center space-y-2 md:space-y-0">
          <span className="text-xs mx-2 my-2">
            Copyright © {new Date().getFullYear()} Gener.us
          </span>
          <span className="hidden md:inline text-xs mx-2">|</span>
          <span className="text-xs mx-2 my-2">All rights reserved.</span>
          <span className="hidden md:inline text-xs mx-2">|</span>
          <Link
            className="underline decoration-[#919189] underline-offset-4 hover:cursor-pointer mx-2 my-2 text-xs"
            to="/legal/terms-of-service"
          >
            Terms of Service
          </Link>
          <span className="hidden md:inline text-xs mx-2">|</span>
          <Link
            className="underline decoration-[#919189] underline-offset-4 hover:cursor-pointer mx-2 my-2 text-xs"
            to="/legal/privacy-policy"
          >
            Privacy Policy
          </Link>
          <span className="hidden md:inline text-xs mx-2">|</span>
          <Link
            className="underline decoration-[#919189] underline-offset-4 hover:cursor-pointer mx-2 my-2 text-xs"
            to="/support"
          >
            Support
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
