import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import './styles/index.css';
import {
  Outlet,
  Links,
  Meta,
  Scripts,
  useRouteError,
  ScrollRestoration,
} from '@remix-run/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import { ExternalScripts } from 'remix-utils/external-scripts';
import { Analytics } from '@vercel/analytics/react';
import { Toaster } from './components/ui/Toaster';
import queryClient from './services/queryClient';
import PageError from './components/app/PageError';
import LoadingOverlay from './components/ui/LoadingOverlay';
import Navbar from './components/app/Navbar';
import Footer from './components/app/Footer';
import clientEnv from './env.client';

export function Layout({ children }: PropsWithChildren) {
  return (
    <html lang="en-US" className="h-screen min-h-screen overflow-auto w-full">
      <head>
        <meta charSet="UTF-8" />
        <meta name="description" content="Donor site for Gather25" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <title>Give to Gather25</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-[#FFFFF0] h-screen min-h-screen flex flex-col overflow-auto w-full">
        <QueryClientProvider client={queryClient}>
          <Navbar />
          <main className="flex-1">{children}</main>
          <Footer />
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
          <Toaster />
          <Analytics
            mode={
              clientEnv?.VITE_APP_ENVIRONMENT === 'prod'
                ? 'production'
                : 'development'
            }
          />
        </QueryClientProvider>
        <Scripts />
        <ExternalScripts />
        <ScrollRestoration />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <PageError errorText="There was an app crash!" />;
}

export function HydrateFallback() {
  return <LoadingOverlay isLoading />;
}

const Root = () => {
  return <Outlet />;
};

export default withSentry(Root);
