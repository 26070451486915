import { Link, useLocation } from '@remix-run/react';
import { useState } from 'react';
import { CloseIcon, Gather25Logo, MobileMenuIcon } from '@/assets/icons';

const menuItems = [
  { id: 'donate', name: 'Donate', path: `/` },
  {
    id: 'campaign',
    name: 'Learn More',
    path: `https://gather25.com`,
  },
  // {
  //   id: 'ripple',
  //   name: 'Ripple',
  //   path: `/ripple`,
  // },
  {
    id: 'results',
    name: 'Results',
    path: `/results`,
  },
];

const Navbar = () => {
  const { pathname } = useLocation();
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const isOnResults = pathname === '/results';

  if (displayMobileMenu) {
    return (
      <div className="absolute z-[100] h-screen w-full overflow-y-hidden bg-[#252525] p-4">
        <div className="flex justify-end hover:cursor-pointer">
          <button
            className="mt-2"
            onClick={() => {
              setDisplayMobileMenu(false);
            }}
          >
            <CloseIcon className="fill-[#FFFFF0]" />
          </button>
        </div>
        <div className={`flex h-full flex-col justify-between pb-14`}>
          <div className="mt-16 flex flex-col items-center space-y-8">
            {menuItems.map(item => {
              return (
                <Link
                  key={item.name}
                  to={item.path}
                  prefetch="intent"
                  onClick={() => {
                    setDisplayMobileMenu(false);
                  }}
                  className={`text-3xl  font-general-sans-medium ${
                    item.path === pathname
                      ? `font-general-sans-semibold w-full rounded-lg bg-[#454444] py-2 text-center text-[#EEEDA8]`
                      : 'text-[#FFFFF0]'
                  }`}
                >
                  {item.name.toUpperCase()}
                </Link>
              );
            })}
          </div>
          <div className="mt-32 flex flex-col items-center">
            <div>
              <a
                href="https://www.gather25.com/"
                className="header-logo w-auto hover:cursor-pointer"
              >
                <Gather25Logo />
                <div className="border-b-[3px] border-b-[#252525]/0" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <nav
      className={`pt-6 pb-6 border-b pr-4 pl-4 flex items-center justify-between w-full h-[67px] ${isOnResults ? 'bg-[#252525] text-[#F2F2E4]' : ''}`}
    >
      <a href="/" className="header-logo w-auto hover:cursor-pointer">
        <Gather25Logo
          className={`ml-4 h-5 ${isOnResults ? 'fill-[#F2F2E4]' : ''}`}
        />
      </a>
      <button
        className="block space-x-10 uppercase md:hidden"
        onClick={() => {
          setDisplayMobileMenu(true);
        }}
      >
        <MobileMenuIcon
          className={isOnResults ? 'fill-[#F2F2E4]' : 'fill-black'}
        />
      </button>
      <div className="hidden space-x-10 uppercase md:flex">
        {menuItems.map(item => {
          return (
            <Link
              key={item.name}
              to={item.path}
              prefetch="intent"
              className={`text-[15px] not-italic font-general-sans-medium hover:border-b-[1px] ${isOnResults ? 'hover:border-b-[#F2F2E4]' : 'hover:border-b-[#454444]'} ${
                item.path === pathname
                  ? `border-b-[1px] font-general-sans-semibold ${isOnResults ? 'border-b-[#F2F2E4]' : 'border-b-[#454444]'}`
                  : ''
              }`}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
